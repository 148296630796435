<!--
    侧边栏
      + 一般用户具有[layout_sidebar_1,layout-sidebar-el-menu-1] 其背景颜色为#101C37
      + 服务用户和服务集成商用户具有[layout_sidebar_2,layout-sidebar-el-menu-2] 其背景颜色为 #132954
 -->
<template>
  <div
    class="layout_sidebar"
    :class="layoutSidebarClass">
    <vue-scroll>
      <el-menu
        class="my-el-menu-no-border"
        :class="layoutSidebarMenuClass"
        :default-active="$route.path"
        :router="true"
        :default-openeds="defaultOpeneds">
        <el-menu-item index="/personal-info">
          <i class="iconfont iconyonghuzhongxin"></i>
          <span
            slot="title"
            class="tw-text-14px">
            用户中心
          </span>
        </el-menu-item>
        <!-- 一般用户 -->
        <template v-if="role === globalVar.USER_ROLE_0">
          <el-menu-item index="/resource-search">
            <i class="iconfont iconshouye"></i>
            <span
              slot="title"
              class="tw-text-14px">
              资源大厅
            </span>
          </el-menu-item>
          <el-menu-item index="/require-manage">
            <i class="iconfont iconxuqiuguanli"></i>
            <span
              slot="title"
              class="tw-text-14px">
              需求管理
            </span>
          </el-menu-item>
          <!-- <el-menu-item index="/expert-manage">
            <i class="iconfont icontuandui"></i>
            <span
              slot="title"
              class="tw-text-14px">
              专家团队
            </span>
          </el-menu-item> -->
          <el-menu-item index="/achievement-manage">
            <i class="iconfont iconziyuanguanli"></i>
            <span
              slot="title"
              class="tw-text-14px">
              成果管理
            </span>
          </el-menu-item>
          <el-submenu index='1'>
            <template slot="title">
              <i class="iconfont icondingdanguanli"></i>
              <span class="tw-text-14px">订单管理</span>
            </template>
            <el-menu-item index="/service-order">
              <span
                slot="title"
                class="tw-text-14px tw-ml-20px">
                服务订单
              </span>
            </el-menu-item>
            <el-menu-item index="/require-order">
              <span
                slot="title"
                class="tw-text-14px tw-ml-20px">
                需求订单
              </span>
            </el-menu-item>
          </el-submenu>
        </template>
        <!-- 服务机构或者服务集成商 -->
        <template v-if="role === globalVar.USER_ROLE_1 || role === globalVar.USER_ROLE_2">
          <el-menu-item index="/index">
            <i class="iconfont iconyonghushouye"></i>
            <span
              slot="title"
              class="tw-text-14px">
              首页
            </span>
          </el-menu-item>
          <el-menu-item index="/resource-search">
            <i class="iconfont iconshouye"></i>
            <span
              slot="title"
              class="tw-text-14px">
              资源大厅
            </span>
          </el-menu-item>
          <el-menu-item :index="`/org-detail/${organization.id}`">
            <i class="iconfont iconjigouguanli"></i>
            <span
              slot="title"
              class="tw-text-14px">
              {{
                {
                  [globalVar.USER_ROLE_1]: '服务用户主页',
                  [globalVar.USER_ROLE_2]: '服务集成商主页'
                }[role]
              }}
            </span>
          </el-menu-item>
          <el-submenu index='2'>
            <template slot="title">
              <i class="iconfont icondingdanguanli"></i>
              <span class="tw-text-14px">订单管理</span>
            </template>
            <el-menu-item index="/service-order">
              <span
                slot="title"
                class="tw-text-14px tw-ml-20px">
                服务订单
              </span>
            </el-menu-item>
            <el-menu-item index="/require-order">
              <span
                slot="title"
                class="tw-text-14px tw-ml-20px">
                需求订单
              </span>
            </el-menu-item>
          </el-submenu>
          <el-menu-item index="/service-manage">
            <i class="iconfont iconfuwu"></i>
            <span
              slot="title"
              class="tw-text-14px">
              服务管理
            </span>
          </el-menu-item>
          <el-menu-item index="/require-manage">
            <i class="iconfont iconxuqiuguanli"></i>
            <span
              slot="title"
              class="tw-text-14px">
              需求管理
            </span>
          </el-menu-item>
          <!-- <el-menu-item index="/expert-manage">
            <i class="iconfont icontuandui"></i>
            <span
              slot="title"
              class="tw-text-14px">
              专家团队
            </span>
          </el-menu-item> -->
          <el-menu-item index="/achievement-manage">
            <i class="iconfont iconziyuanguanli"></i>
            <span
              slot="title"
              class="tw-text-14px">
              成果管理
            </span>
          </el-menu-item>
          <!-- 必须是非挂靠关系才有成员列表 进行成员的挂靠审核 -->
          <el-submenu index='3' v-if="userInfo.organizationRole">
            <template slot="title">
              <i class="iconfont iconzhanghaoguanli"></i>
              <span class="tw-text-14px">成员管理</span>
            </template>
            <el-menu-item index="/member-list">
              <span
                slot="title"
                class="tw-text-14px tw-ml-20px">
                成员列表
              </span>
            </el-menu-item>
            <el-menu-item index="/gk-audit">
              <span
                slot="title"
                class="tw-text-14px tw-ml-20px">
                挂靠审核
              </span>
            </el-menu-item>
          </el-submenu>
          <el-menu-item index="/info-manage">
            <i class="iconfont iconliaotian"></i>
            <span
              slot="title"
              class="tw-text-14px">
              资讯管理
            </span>
          </el-menu-item>
        </template>
      </el-menu>
    </vue-scroll>
  </div>
</template>

<script>
import globalVar from '@/configs/globalVar'

export default {
  computed: {
    layoutSidebarClass () {
      return {
        layout_sidebar_1: this.role === globalVar.USER_ROLE_0,
        layout_sidebar_2: this.role === globalVar.USER_ROLE_1 || this.role === globalVar.USER_ROLE_2
      }
    },
    layoutSidebarMenuClass () {
      return {
        'layout-sidebar-el-menu': true,
        'layout-sidebar-el-menu-1': this.role === globalVar.USER_ROLE_0,
        'layout-sidebar-el-menu-2': this.role === globalVar.USER_ROLE_1 || this.role === globalVar.USER_ROLE_2
      }
    },
    defaultOpeneds () {
      return {
        [globalVar.USER_ROLE_0]: ['1'],
        [globalVar.USER_ROLE_1]: ['1', '2', '3'],
        [globalVar.USER_ROLE_2]: ['1', '2', '3']
      }[this.role]
    }
  }
}
</script>

<style lang="scss" scoped>
.layout_sidebar {
  width: 210px;
  height: 100%;
  &_1 {
    background-color: #101C37;
  }
  &_2 {
    background-color: #132954;
  }
}
</style>
