<template>
  <div class="layout_header header tw-flex tw-justify-between tw-items-center">
    <div class="header_left tw-flex tw-items-center" @click="onHome">
      <img class="header_left_logo" src="../../assets/images/logo.png" alt="">
      <div class="header_left_title tw-flex tw-items-center">
        <p class="header_left_title-main tw-text-18px tw-font-bold">共创云</p>
        <p class="header_left_title-sub tw-text-14px">
          {{
            {
              [globalVar.USER_ROLE_0]: '普通用户工作台',
              [globalVar.USER_ROLE_1]: '服务用户工作台',
              [globalVar.USER_ROLE_2]: '服务集成商工作台'
            }[role]
          }}
        </p>
      </div>
    </div>
    <div class="header_right tw-flex tw-items-center">
      <router-link
        tag="a"
        target="_blank"
        to="/chat"
        class="message_icon_box">
        <i class="iconfont icontongzhi"></i>
      </router-link>
      <el-popover
        class="avatar_popover"
        placement="bottom-end"
        trigger="click"
        :visible-arrow="false"
        :offset="0"
        width="160">
        <div class="avatar_popover_content">
          <!--
            从显现上看此处的值为：
              + 法人：联系人
              + 挂靠：姓名
          -->
          <p class="content_name tw-text-black tw-text-16px tw-font-bold">
            {{userInfo.username}}
          </p>
          <p class="content_mobile tw-text-secondary-text tw-text-14px">
            {{userInfo.mobile}}
          </p>
          <div class="content_bottom">
            <!-- <div class="handle_item" @click="open('/personal-info', {})">
              <i class="iconfont iconyonghuzhongxin tw-mr-20px"></i>
              <h1 class="tw-text-14px">用户中心</h1>
            </div> -->
            <div class="handle_item" @click="logOut">
              <i class="iconfont icontuichudenglu tw-mr-20px"></i>
              <h1 class="tw-text-14px">退出登录</h1>
            </div>
          </div>
        </div>
        <div slot="reference" class="avatar_popover_reference">
          <user-avatar :url="userInfo.avatarUrl" />
        </div>
      </el-popover>
    </div>
  </div>
</template>

<script>
import userAvatar from '@/components/user-avatar'
import cookie from '@/configs/cookie'
import globalVar from '@/configs/globalVar'

export default {
  components: {
    userAvatar
  },
  computed: {
    globalVar () {
      return globalVar
    },
    userInfo () {
      return this.$store.state.userInfo
    },
    role () {
      return this.userInfo.role
    },
    // 官网地址
    homePath () {
      return process.env.VUE_APP_HOME_PATH
    }
  },
  mounted () {
    this.$store.dispatch('getUserInfo')
  },
  methods: {
    open (path, query) {
      const { href } = this.$router.resolve({
        path,
        query
      })

      window.open(href, '_blank')
    },
    // 进入官网首页
    onHome () {
      window.open(this.homePath)
    },
    logOut () {
      this.$store.commit('setUserInfo', {})
      cookie.remove(this.globalVar.WEB_TOKEN)
      this.$router.replace('/login')
    }
  }
}
</script>

<style lang="scss" scoped>
.layout_header {
  width: 100%;
  height: 60px;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
}
.header {
  padding: 0 30px 0 20px;
  &_left {
    cursor: pointer;
    &_logo {
      margin-right: 10px;
    }
    &_title {
      &-main {
        position: relative;
        line-height: 25px;
        color: #000000;
        margin-right: 21px;
        &::after {
          content: "";
          position: absolute;
          width: 1px;
          height: 20px;
          right: -10px;
          top: 50%;
          transform: translateY(-50%);
          background-color: #DCDFE6;
        }
      }
      &-sub {
        line-height: 20px;
        color: #606266;
      }
    }
  }
  &_right {
    .message_icon_box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 100%;
      cursor: pointer;
      margin-right: 46px;
      color: #051D40;
      &:hover {
        color: #3473E6;
      }
    }
    .avatar_popover {
      .avatar_popover_reference {
        display: flex;
        align-items: center;
        height: 32px;
        cursor: pointer;
      }
    }
  }
}

.avatar_popover_content {
  padding: 10px 16px;
  .content_name {
    line-height: 40px;
  }
  .content_mobile {
    line-height: 30px;
    margin-bottom: 6px;
  }
  .content_bottom {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 6px;
    border-top: 1px solid #DCDFE6;
    .handle_item {
      display: flex;
      align-items: center;
      padding-left: 10px;
      height: 40px;
      transition: all 0.3s ease;
      cursor: pointer;
      &:hover {
        background-color: $--color-primary-light-9;
      }
    }
  }
}
</style>
