<template>
  <div class="layout tw-flex tw-flex-col">
    <layout-header />
    <div class="layout_main tw-flex-1 tw-flex">
      <layout-sidebar />
      <div class="layout_content tw-flex-1">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import layoutHeader from './layout-header'
import layoutSidebar from './layout-sidebar'

export default {
  components: {
    layoutHeader,
    layoutSidebar
  }
}
</script>

<style lang="scss" scoped>
.layout {
  width: 100%;
  height: 100%;
  &_main {
    overflow: hidden;
  }
  &_content {
    overflow: auto;
    background-color: #EDEFF2;
    padding: 30px 20px 30px 20px;
  }
}
</style>
